<template>
  <el-main>
    <div class="form">
      <div class="tips">注意：经营分佣%+城市合伙人分佣%+区域合伙人分佣%+市场合伙人分佣必须小于等于100%</div>
      <div class="form-info">
        <div class="title">经营分佣：</div>
        <div class="form-put">
          <div class="put">
            <el-input v-model="commission_rate" type="number">
              <template slot="append">%</template>
            </el-input>
          </div>
          <div class="explain">
            <p>时价：会员身份，并且分享时价给其他人购买，非会员用户分享不享受佣金。</p>
            <p>合伙人入驻：会员身份，并且分享会员入驻信息给其他人入会。非会员用户分享不享受佣金。</p>
            <p>项目合作：创建合作并支付了合作费用的会员。</p>
            <p>项目活动：创建活动的会员（不需要支付费用）。</p>
            <p>应用数据：（服务商品类）：用户购买服务的店铺的所有者为经营者（可为自己店铺，也可以为代销店铺）。</p>
            <p>实物商品：用户购买服务的店铺的所有者为经营者（可为自己店铺，也可以为代销店铺）。</p>
            <p>广告/关键词：无经营者身份，无经营佣金。</p>
          </div>
        </div>
      </div>
      <div class="form-info">
        <div class="title">城市合伙人分佣：</div>
        <div class="form-put">
          <div class="put">
            <el-input v-model="identity_type_city_commission_rate" type="number">
              <template slot="append">%</template>
            </el-input>
            <span>一个城只支持1个城市合伙人</span>
          </div>
          <div class="explain">
            <p>时价：判断分享会员所在城市，如没有分享人，直接平台订阅则没有城市合伙人分佣。</p>
            <p>合伙人入驻：判断邀请其他人入驻的会员，其所在城市，直接平台入驻则没有城市合伙人分佣。</p>
            <p>项目合作：判断创建合作会员所在的城市。</p>
            <p>项目活动：判断创建活动的会员（不需要支付费用）所在的城市。</p>
            <p>应用数据：（服务商品类）：判断售卖的店铺所在城市，（可为自己店铺，也可以为代销店铺）。</p>
            <p>实物商品：判断售卖的店铺所在城市（可为自己店铺，也可以为代销店铺）。</p>
            <p>广告/关键词：判断付费购买广告/关键词的会员所在的城市。</p>
          </div>
        </div>
      </div>
      <div class="form-info">
        <div class="title">区域合伙人分佣：</div>
        <div class="form-put">
          <div class="put">
            <el-input v-model="identity_type_area_commission_rate" type="number">
              <template slot="append">%</template>
            </el-input>
            <span>一个区域最多支持3个合伙人，总计分佣为此处添加佣金3倍</span>
          </div>
          <div class="explain">
            <p>时价：判断分享会员所在区域，如没有分享人，直接平台订阅则没有区域合伙人分佣。</p>
            <p>合伙人入驻：判断邀请其他人入驻的会员，其所在区域，直接平台入驻则没有区域合伙人分佣。</p>
            <p>项目合作：判断创建合作会员所在的区域。</p>
            <p>项目活动：判断创建活动的会员（不需要支付费用）所在的区域。</p>
            <p>应用数据：（服务商品类）：判断售卖的店铺所在区域，（可为自己店铺，也可以为代销店铺）。</p>
            <p>实物商品：判断售卖的店铺所在区域（可为自己店铺，也可以为代销店铺）。</p>
            <p>广告/关键词：判断付费购买广告/关键词的会员所在的区域。</p>
          </div>
        </div>
      </div>
      <div class="form-info">
        <div class="title">市场合伙人分佣：</div>
        <div class="form-put">
          <div class="put">
            <el-input v-model="identity_type_market_commission_rate" type="number">
              <template slot="append">%</template>
            </el-input>
            <span>一个区域最多支持3个合伙人，总计分佣为此处添加佣金3倍</span>
          </div>
          <div class="explain">
            <p>时价：无市场合伙人佣金。</p>
            <p>合伙人入驻：无市场合伙人佣金。</p>
            <p>项目合作：无市场合伙人佣金。</p>
            <p>项目活动：无市场合伙人佣金。</p>
            <p>应用数据：（服务商品类）：判断售卖的服务所属的三级分类，给所在分类的市场合伙人分佣。</p>
            <p>实物商品：无市场合伙人佣金。</p>
            <p>广告/关键词：无市场合伙人佣金。</p>
          </div>
        </div>
      </div>
    </div>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
export default {
  components: {
    Preservation,
  },
  data() {
    return {
      commission_rate: '',
      identity_type_city_commission_rate: '',
      identity_type_area_commission_rate: '',
      identity_type_market_commission_rate: '',
    };
  },
  created() {
    this.$axios.post(this.$api.gateway.member.commissionSet).then(res => {
      if (res.code == 0) {
        this.commission_rate = res.result.commission_rate;
        this.identity_type_city_commission_rate = res.result.identity_type_city_commission_rate;
        this.identity_type_area_commission_rate = res.result.identity_type_area_commission_rate;
        this.identity_type_market_commission_rate = res.result.identity_type_market_commission_rate;
      }
    });
  },
  methods: {
    preservation() {
      try {
        if (!this.commission_rate) throw '请填写经营分佣';
        if (!this.identity_type_city_commission_rate) throw '请填城市合伙人分佣';
        if (!this.identity_type_area_commission_rate) throw '请填区域合伙人分佣';
        if (!this.identity_type_market_commission_rate) throw '请填市场合伙人分佣';
        this.$axios
          .post(this.$api.gateway.member.commissionEdit, {
            commission_rate: this.commission_rate,
            identity_type_city_commission_rate: this.identity_type_city_commission_rate,
            identity_type_area_commission_rate: this.identity_type_area_commission_rate,
            identity_type_market_commission_rate: this.identity_type_market_commission_rate,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('保存成功');
            } else {
              this.$message.error(res.msg);
            }
          });
      } catch (e) {
        this.$message.warning(e);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  padding-bottom: 80px;
  background: #fff;
  font-size: 14px;
  .form {
    padding-left: 50px;
    .tips {
      color: #f56c6c;
      margin-bottom: 20px;
    }
    .form-info {
      display: flex;
      margin-bottom: 20px;
      .title {
        line-height: 40px;
        color: #606266;
        width: 120px;
        text-align: right;
      }
      .form-put {
        .put {
          display: flex;
          align-items: center;
          span {
            margin-left: 15px;
          }
        }
        .el-input {
          width: 500px;
        }
      }
      .explain {
        color: #999;
        p:nth-child(1),
        p:nth-child(3),
        p:nth-child(5),
        p:nth-child(7) {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>